<template>
  <div>
    <!-- 頭部組件 -->
    <nav-header></nav-header>

    <div class="app-container period">
      <div class="box">
        <img src="@/assets/image/login/warn.png" alt="非開放期間">
        <h2>抱歉現在是非開放期間</h2>
        <h3>系統將在{{count}}秒後自動連結到JEPT官方網站。</h3>
      </div>
    </div>

    <!-- 底部組件 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
export default {
  name: 'Period',
  data() {
    return {
      // 定时器
      count: 5,
      timer: null
    };
  },
  created() {
    this.sendCode()
  },
  methods: {
    sendCode(){
      this.timer= setInterval(()=>{
        // 创建定时器
        if(this.count === 0){
          // 关闭定时器
          clearInterval(this.timer)
          this.timer = null
          this.count = 0
          window.location.replace('http://jept.joy.com.tw/')
        }else{
          this.count--
        }
      },1000)
    },
  },
  // 销毁组件前清除定时器
  beforeDestroy() {
    clearInterval(this.timer);  
    this.timer = null;
  }
}
</script>

<style lang="less" scoped>
.period {
  .box {
    padding: 108px 0;
    margin: 100px auto 50px;
    width: 1320px;
    background: #FFFFFF;
    border-radius: 20px;
    text-align: center;
    img {
      width: 313px;
      height: 233px;
    }
    h2 {
      margin: 36px 0 16px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 53px;
      color: #1D1F23;
    }
    h3 {
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 48px;
      text-align: center;
      color: #9F9FA1;
    }
  }
}
</style>